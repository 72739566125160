<template>
  <div class="container felx flex-center">
    <div class="content">
      <img @click="close" src="../assets/images/guanbi.png" class="guanbi"/>
      <div class="felx flex-center t-box">
        <img src="../assets/images/zhuangshi.png" class="zhuangshi" alt="">
        <div class="title">贷款申请通道</div>
        <img src="../assets/images/zhuangshi.png" class="zhuangshi" alt="">
      </div>
      <!--      表单-->
      <form>
        <div class="l-input">
          <input class="input-con" v-model="formData.name" type="text" placeholder="请输入您的姓名"/>
        </div>
        <div class="l-input felx flex-space">
          <input class="input-con" v-model="formData.applyMoney" type="number" placeholder="请输入申请贷款金额"/>
          <span>（万元）</span>
        </div>
        <div class="l-input">
          <input class="input-con" v-model="formData.phone" type="number"
                 oninput="if(value.length>11)value=value.slice(0,11)"
                 placeholder="请输入您的手机号码"/>
        </div>
        <div class="l-input felx flex-space">
          <input v-model="formData.code" class="input-con" type="number" placeholder="请输入您验证码"/>
          <div class="line"></div>
          <div :class="['code-text',{'active-code':isActive}]" @click="getCode">
            {{ isActive ? num + 's后重新获取' : '获取验证码' }}
          </div>
        </div>
        <div class="btn" @click="submit">立即申请</div>
      </form>
    </div>


  </div>
</template>

<script>
import {Toast} from "vant";


export default {
  name: "loan-application",
  data() {
    return {
      formData: {
        name: '',//申请人名
        applyMoney: '',//申请人名
        phone: '',//手机号码
        code: '',//验证码

      },
      isActive: false,//是否获取验证码
      num: 60,//倒计时

    }
  },
  mounted() {
  },
  methods: {
    //提交
    submit() {
      this.$axios.post(`/business/mobile/applyLoanDTO`, this.formData).then(() => {
        this.$emit('showSucc');
      })
    },
    //倒计时
    countdown() {
      let timer = setInterval(() => {
        --this.num;
        if (this.num <= 0) {
          this.isActive = false;
          this.num = 60;
          clearInterval(timer);
        }
      }, 1000)
    },
    //获取验证嘛
    getCode() {
      if (!this.isActive) {
        if (this.formData.phone.length == 11) {
          this.$axios.get(`/business/mobile/getCode/${this.formData.phone}`).then(() => {
            this.isActive = true;
            this.countdown();
          })
        } else {
          Toast('填写完整手机号')
        }
      }

    },

    //关闭弹层
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  touch-action: none;
}

.content {
  width: 315px;
  height: 370px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  padding: 30px 15px 0;
}

.guanbi {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.t-box {
  margin-bottom: 25px;
}

.zhuangshi {
  width: 17px;
  height: 9px;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #FB560A;
  margin: 0 17px;
}

.active-code {
  color: #999999;
}

</style>