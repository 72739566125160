<template>
  <div class="container felx flex-center">
    <div class="content">
      <img @click="close" src="../assets/images/guanbi.png" class="guanbi"/>
      <div class="con">
        <img src="../assets/images/chenggong@2x.png" class="chenggong">
        <div class="cg-text">申请成功</div>
        <div class="tips">请保持通讯通畅，客户经理将尽快与您联系!</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "application-successful",
  methods:{
    close(){
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 10;
  touch-action: none;
}

.content {
  width: 315px;
  height: 240px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  padding: 30px 15px 0;
}

.guanbi {
  width: 14px;
  height: 14px;
  position: absolute;
  right: 15px;
  top: 15px;
}
.con{
  text-align: center;
}
.chenggong{
  width: 84px;
  height: 84px;
  /*margin-bottom: 18px;*/
}
.cg-text{
  font-size: 18px;
  color: #333333;
}
.tips{
  font-size: 13px;
  color: #999999;
  margin-top: 39px;
}

</style>