<template>
  <div>
    <!--      banner-->
    <div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index">
          <img :src="item.itemValue" class="banner" alt="">
        </van-swipe-item>
      </van-swipe>

    </div>
    <div class="num-box felx flex-center ">
      <div class="flex-1 felx-d felx flex-center">
        <span class="num-text">10万+</span>
        <span class="n-text">服务客户</span>
      </div>
      <div class="num-line"></div>
      <div class="flex-1 felx-d felx flex-center">
        <span class="num-text">30亿+</span>
        <span class="n-text">促成贷款金额</span>
      </div>
      <div class="num-line"></div>
      <div class="flex-1 felx-d felx flex-center">
        <span class="num-text">60家+</span>
        <span class="n-text">合作机构</span>
      </div>
    </div>
    <div class="call-phone">
      <div class="call-phone-box">
        <div class="felx flex-space call-phone-item">
          <div class="felx flex-a">
            <img src="../../assets/images/dianhua@2x.png" class="dianhua" alt="">
            <span class="call-phone-text">贷款热线：<span class="call-phone-t">023-67456888</span> </span>
          </div>
          <a href="tel:023-67456888" class="call-phone-btn">点击联系</a>
        </div>
        <div class="felx flex-space call-phone-item">
          <div class="felx flex-a">
            <img src="../../assets/images/banli@2x.png" class="dianhua" alt="">
            <span class="call-phone-text">贷款办理：<span class="call-phone-t">10分钟内联系您</span> </span>
          </div>
          <div class="call-phone-btn" @click="showLayer=!showLayer">点击申请</div>
        </div>
        <div class="felx flex-space call-phone-item">
          <div class="felx flex-a">
            <img src="../../assets/images/pinggu@2x.png" class="dianhua" alt="">
            <span class="call-phone-text">贷款评估：<span class="call-phone-t">快速测出可贷额度</span> </span>
          </div>
          <router-link to="/loan-amount-measurement" class="call-phone-btn">点击测额</router-link>
        </div>
      </div>
    </div>
    <!--    通知-->
    <div class="notice felx flex-a">
      <img src="../../assets/images/tongzhi@2x.png" class="tongzhi" alt="">
      <div class="tz-line"></div>
      <van-notice-bar color="#333333" background="#fff" :scrollable="false">
        <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
        >
          <van-swipe-item v-for="(item,index) in loanNoticeList" :key="index">{{item.tel}} {{item.user}}{{item.productName}}放款{{item.loanMoney}}万元</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <!--    优势-->
    <div class="advantage">
      <div class="felx flex-center ys-t-b">
        <div class="ys-line"></div>
        <div class="ys-title">德润消科值得信赖四大优势</div>
        <div class="ys-line"></div>
      </div>
      <div class="ys-item felx flex-a">
        <div class="ys-item-w">1、高</div>
        <div class="ys-item-t">放款额度高，房屋评估价值高，高至8.5成</div>
      </div>
      <div class="ys-item felx flex-a">
        <div class="ys-item-w">2、快</div>
        <div class="ys-item-t">放款快速，高效率服务，快至5个工作日</div>
      </div>
      <div class="ys-item felx flex-a">
        <div class="ys-item-w">3、松</div>
        <div class="ys-item-t">通过率99%，无M4、当前逾期可进件</div>
      </div>
      <div class="ys-item felx flex-a">
        <div class="ys-item-w">4、省</div>
        <div class="ys-item-t">利率低，省钱，省心，银行放款更有保障</div>
      </div>

    </div>
    <!--    贷款流程-->
    <div class="loan-process">
      <div class="dklc-title felx flex-center">
        <div class="ys-line"></div>
        <div class="ys-title">贷款流程 拿钱攻略</div>
        <div class="ys-line"></div>
      </div>
      <div class="bz">
        <span class="bz1">4步到帐 </span>
        <span class="bz1 bz2">比想象更简单</span>
      </div>
      <div class="felx flex-space sqbz">
        <div class="bz-item flex-1 felx felx-d flex-center">
          <img src="../../assets/images/shenqing@2x.png" class="shenqing" alt="">
          <div class="bz-item-t">在线申请</div>
        </div>
        <div class="triangle_border_right"></div>
        <div class="bz-item flex-1 felx felx-d flex-center">
          <img src="../../assets/images/xiahu@2x.png" class="shenqing" alt="">
          <div class="bz-item-t">下户评估</div>
        </div>
        <div class="triangle_border_right"></div>
        <div class="bz-item flex-1 felx felx-d flex-center">
          <img src="../../assets/images/shenhe@2x.png" class="shenqing" alt="">
          <div class="bz-item-t">风控审核</div>
        </div>
        <div class="triangle_border_right"></div>
        <div class="bz-item flex-1 felx felx-d flex-center">
          <img src="../../assets/images/fangkuan@2x.png" class="shenqing" alt="">
          <div class="bz-item-t">办押放款</div>
        </div>
      </div>
    </div>
    <!--    分割线-->
    <div class="line-index"></div>
    <!--    产品-->
    <div class="product-box">
      <div class="product-title felx flex-center">
        <div class="ys-line"></div>
        <div class="ys-title">推荐产品</div>
        <div class="ys-line"></div>
      </div>
      <div class="product-list">
        <div class="p-item felx">
          <img src="../../assets/images/anjufen@2x.png" class="anjufen" alt="">
          <div class="flex-1">
            <div class="p-title">担保安居分</div>
            <div class="miaosu">重庆有房即可贷，额度高，低门槛，99%通过率</div>
            <div class="felx flex-a">
              <div class="dk-box">
                <div class="dk-text">可贷额度</div>
                <div class="dk-num">5-80万</div>
              </div>
              <div class="dk-box">
                <div class="dk-text">贷款期限</div>
                <div class="dk-num">3年期</div>
              </div>
            </div>
            <div class="hkfs">
              <div class="hkfs-text">还款方式：先息后本</div>
              <router-link to="/apply-immediately?id=13" class="hkfs-btn">一键申请</router-link>
            </div>
          </div>
        </div>
        <div class="p-item felx">
          <img src="../../assets/images/leyebao@2x.png" class="anjufen" alt="">
          <div class="flex-1">
            <div class="p-title">乐业保</div>
            <div class="miaosu">按揭房，抵押房再贷款，最高可贷85成</div>
            <div class="felx flex-a">
              <div class="dk-box">
                <div class="dk-text">可贷额度</div>
                <div class="dk-num">5-90万</div>
              </div>
              <div class="dk-box">
                <div class="dk-text">贷款期限</div>
                <div class="dk-num">5年期</div>
              </div>
            </div>
            <div class="hkfs">
              <div class="hkfs-text">还款方式：等额等息</div>
              <router-link to="/apply-immediately?id=3" class="hkfs-btn">一键申请</router-link>
            </div>
          </div>
        </div>
        <div class="p-item felx">
          <img src="../../assets/images/rongyindai@2x.png" class="anjufen" alt="">
          <div class="flex-1">
            <div class="p-title">融银贷</div>
            <div class="miaosu">房屋一押二押，有案件也可进件（提供结案证明）</div>
            <div class="felx flex-a">
              <div class="dk-box">
                <div class="dk-text">可贷额度</div>
                <div class="dk-num">5-100万</div>
              </div>
              <div class="dk-box">
                <div class="dk-text">贷款期限</div>
                <div class="dk-num">3年期</div>
              </div>
            </div>
            <div class="hkfs">
              <div class="hkfs-text">还款方式：先息后本</div>
              <router-link to="/apply-immediately?id=11" class="hkfs-btn">一键申请</router-link>
            </div>
          </div>
        </div>
        <div class="p-item felx">
          <img src="../../assets/images/anjufen1@2x.png" class="anjufen" alt="">
          <div class="flex-1">
            <div class="p-title">安居分</div>
            <div class="miaosu">按揭房还款满一年再贷款，快至24小时放款</div>
            <div class="felx flex-a">
              <div class="dk-box">
                <div class="dk-text">可贷额度</div>
                <div class="dk-num">5-80万</div>
              </div>
              <div class="dk-box">
                <div class="dk-text">贷款期限</div>
                <div class="dk-num">2-10年</div>
              </div>
            </div>
            <div class="hkfs">
              <div class="hkfs-text">还款方式：等额本息/先息后本</div>
              <router-link to="/apply-immediately?id=4" class="hkfs-btn">一键申请</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    分割线-->
    <div class="line-index"></div>
    <!--    资讯-->
    <div class="info-box">
      <div class="zx-title felx flex-space">
        <div class="zx-t-text">贷款资讯</div>
        <router-link to="/loan-info" class="more-text">更多</router-link>
      </div>
      <div>
        <a  :href="item.url" v-for="(item,index) in newList" class="zx-item" :key="index">{{item.title}}</a>
      </div>
    </div>
    <!--    按钮-->
    <div class="btn-box felx flex-space">
      <a href="http://ws.daitougou.com/ws/userCli" class="felx flex-center felx-d zx-box">
        <img src="../../assets/images/zixun@2x.png" class="zixun" alt="">
        <span class="zx-text">咨询</span>
      </a>
      <a href="tel:023-67456888" class="cp-btn felx flex-center">
        <img src="../../assets/images/dh@2x.png" class="dh-icon" alt="">
        <span class="cp-text">拨打电话</span>
      </a>
    </div>
    <!--  贷款申请弹层-->

    <loanApplication v-if="showLayer" @close="showLayer=!showLayer" @showSucc="isSucc=true,showLayer=false"></loanApplication>
    <!--    申请成功弹层-->
    <applicationSuccessful @close="isSucc=!isSucc"  v-if="isSucc"></applicationSuccessful>

  </div>
</template>

<script>
import loanApplication from '../../components/loan-application';
import applicationSuccessful from '../../components/application-successful';
export default {
  name: "home",
  components: {loanApplication,applicationSuccessful},
  data(){
    return{
      showLayer:false,//显示申请弹层
      bannerList:[],//banner
      loanNoticeList:[],//放款通知
      newList:[],//资讯列表
      isSucc:false,//是否申请成功
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'spage';
    this.getBanner();
    this.getLoanNotice();
    this.getNewList();
  },
  beforeDestroy() {
    document.getElementsByTagName('body')[0].className = '';
  },
  methods:{
    //获取资讯
    getNewList(){
      this.$axios.get(`/business/websiteNews/newsForUccn`,{params:{pageNo:1,pageSize:5,classify:1}}).then((res)=>{
        this.newList=res.data.data.records;
      })
    },
    //放款通知
    getLoanNotice(){
      this.$axios.get(`/business/mobile/mobileLoanNotice`).then(res=>{
        this.loanNoticeList=res.data.data;
      })
    },
    //获取banner
    getBanner(){
      this.$axios.get(`/dictItem/findByDictCode/mobile_banner`,{changeUrl:true}).then(res=>{
        this.bannerList=res.data.data;
        console.log(res)
      })
    },
  },
}
</script>

<style scoped>

.banner {
  width: 100%;
  height: 190px;
}

.num-box {
  padding: 0 15px;
  height: 70px;
  width: 100%;
}

.num-text {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
}

.n-text {
  font-size: 12px;
  color: #999999;
  line-height: 20px;
}

.num-line {
  width: 1px;
  height: 25px;
  background-color: #CCCCCC;
}

.call-phone {
  padding: 0 15px;
}

.call-phone-box {
  width: 100%;
  padding: 21px 15px 1px;
  background: #F8FBFF;
  border-radius: 8px;
}

.dianhua {
  width: 15px;
  height: 15px;
  margin-right: 7px;
}

.call-phone-text {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}

.call-phone-t {
  font-weight: normal;
}

.call-phone-btn {
  width: 75px;
  height: 28px;
  background: #FB560A;
  border-radius: 14px;
  font-size: 13px;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
}

.call-phone-item {
  margin-bottom: 14px;
}

/*通知*/
.notice {
  height: 50px;
  padding: 0 15px;
}

.tongzhi {
  width: 33px;
  height: 13px;
  margin-right: 9px;
}

.tz-line {
  width: 1px;
  height: 12px;
  background: #999999;
  margin-right: 6px;
}

/*优势*/
.advantage {
  background-color: #F8F8F8;
  width: 100%;
  padding: 28px 15px 13px;
}

.ys-line {
  width: 15px;
  height: 1px;
  background: #333333;
  border-radius: 1px;
}

.ys-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 0 13px;
}

.ys-t-b {
  margin-bottom: 28px;
}

.ys-item {
  width: 100%;
  height: 40px;
  border: 1px solid #3F85FF;
  border-radius: 20px;
  margin-bottom: 15px;
}

.ys-item-w {
  width: 70px;
  height: 100%;
  background: #3F85FF;
  border: 1px solid #3F85FF;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  border-radius: 20px;
}

.ys-item-t {
  font-size: 13px;
  color: #333333;
  /*line-height: 40px;*/
  margin-left: 6px;
  display: flex;
  justify-content: center;
}

/*贷款流程*/
.loan-process {
  padding: 30px 0 0;
}

.dklc-title {
  margin-bottom: 14px;
}

.bz {
  text-align: center;
  margin-bottom: 20px;
}

.bz1 {
  font-size: 13px;
  color: #FB560A;
}

.bz2 {
  color: #999999;
}

.bz-item {

}

.sqbz {
  padding: 0 15px 28px;
}

.shenqing {
  width: 41px;
  height: 41px;
  margin-bottom: 5px;
}

.bz-item-t {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.triangle_border_right {
  width: 0;
  height: 0;
  border-width: 5px 0 5px 6px;
  border-style: solid;
  border-color: transparent transparent transparent #3F85FF; /*透明 透明  灰*/
  position: relative;
  top: 22px;
  margin: 0 10px;
}

.line-index {
  width: 100%;
  height: 8px;
  background: #F8F8F8;
}

/*产品*/
.product-box {
  padding: 28px 15px 0;
}

.product-title {
  margin-bottom: 30px;
}

.p-item {
  border-bottom: 1px solid #EEEEEE;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.p-item:last-child {
  border-bottom: none;
}

.anjufen {
  width: 33px;
  height: 33px;
  margin-right: 16px;
}

.p-title {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.miaosu {
  display: inline-block;
  padding: 6px 12px;
  font-size: 12px;
  color: #FC4303;
  background: rgba(252, 67, 3, 0.1);
  border-radius: 2px;
  margin-bottom: 16px;
}

.dk-box {
  margin-right: 53px;
}

.dk-text {
  font-size: 12px;
  color: #999999;
  margin-bottom: 10px;
}

.dk-num {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.hkfs {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.hkfs-text {
  font-size: 14px;
  color: #333333;
}

.hkfs-btn {
  width: 80px;
  height: 34px;
  background: #3F85FF;
  border-radius: 17px;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 34px;
  text-align: center;
}

.info-box {
  padding: 10px 15px;
}

.zx-title {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #DFDFDF;
  margin-bottom: 10px;
}

.zx-t-text {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.more-text {
  font-size: 13px;
  color: #999999;
}

.zx-item {
  margin: 10px 0 10px;
  display: block;
}

.zx-item {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}

.btn-box {
  width: 100%;
  height: 49px;
  background-color: #fff;
  padding: 0 15px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.zixun {
  width: 18px;
  height: 18px;
}

.zx-text {
  font-size: 10px;
  color: #333333;
}

.zx-box {
  margin: 0 30px 0 10px;
}

.cp-btn {
  flex: 1;
  width: 100%;
  height: 40px;
  background: #FB560A;
  border-radius: 20px;
}

.dh-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.cp-text {
  font-size: 16px;
  color: #FFFFFF;
}



</style>
<style>
.my-swipe{
  z-index: 1;
  position: relative;
}
.notice-swipe {
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}

.van-notice-bar {
  flex: 1;
  padding: 0;
}

</style>